const PublicityNavs = [
  {
    id: '1', label: '内容配置', path: '/', children: [
      {
        id: '1-1', label: 'banner 配置', path: `/index/advertising/banner/list`, childrenPath: [
          '/index/advertising/banner/create',
          '/index/advertising/banner/edit',
          '/index/advertising/banner/detail',
        ],
      },
      {
        id: '1-2', label: '贴片配置', path: `/index/advertising/sticker/list`, childrenPath: [
          '/index/advertising/sticker/create',
          '/index/advertising/sticker/edit',
          '/index/advertising/sticker/detail',
        ],
      },
      // {
      //   id: '1-3', label: '勋章配置', path: `/index/advertising/medalList`, childrenPath: [
      //     '/index/advertising/medalDetail',
      //   ],
      // },
    ],
  },
];

const stickerDetail = [
  { id: '1', title: '新建贴片', path: '/index/advertising/sticker/create' },
  { id: '2', title: '编辑贴片', path: '/index/advertising/sticker/edit' },
  { id: '3', title: '贴片详情', path: '/index/advertising/sticker/detail' },
];

export {
  stickerDetail,
};

export default PublicityNavs;
