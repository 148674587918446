<template>
  <a-form :form="form"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 18 }">
    <a-form-item label="上传图片">
      <a-upload v-decorator="['pic', decorator.pic]"
                :multiple="false"
                accept=".jpg, .jpeg, .png"
                list-type="picture-card"
                :disabled="readonly || uploading"
                :show-upload-list="false"
                :custom-request="customRequest"
                :beforeUpload="beforeUpload">
        <img v-if="pic" :src="pic" alt="封面" class="cover-img"/>
        <div v-else>
          <a-icon :type="loading ? 'loading' : 'plus'"/>
          <div class="ant-upload-text">上传</div>
        </div>
      </a-upload>
      <div class="input-hint">
        建议尺寸：480x270,请上传列表内图片(最多上传1张)格式：jpg,jpeg,png,gif, 图片文件大小不能超过2M
      </div>
    </a-form-item>

    <a-form-item label="是否需要token">
      <a-radio-group v-decorator="['is_token', decorator.is_token]"
                     name="is_white"
                     :disabled="readonly">
        <a-radio :value="0">不需要</a-radio>
        <a-radio :value="1">需要</a-radio>
      </a-radio-group>
    </a-form-item>

    <a-form-item label="定时上架时间">
      <a-date-picker v-decorator="['shutup_time', decorator.shutup_time]"
                     show-time
                     :disabled="readonly"
                     :disabled-date="disabledStartDate"
                     :disabled-time="disabledStartTime"
                     @change="shutUpTimeChange"/>
      <div class="input-hint">不选择上架时间，则点击“上架”后立即上架</div>
    </a-form-item>
    <a-form-item label="定时下架时间">
      <a-date-picker v-decorator="['shutdown_time', decorator.shutdown_time]"
                     show-time
                     :disabled="readonly"
                     :disabled-date="disabledEndDate"
                     :disabled-time="disabledEndTime"
                     @change="shutDownTimeChange"/>
      <div class="input-hint">不选择下架时间，则需要手动下架</div>
    </a-form-item>

    <a-form-item label="白名单">
      <a-radio-group v-decorator="['is_white', decorator.is_white]"
                     name="is_white"
                     :disabled="readonly"
                     @change="whiteStatusChange">
        <a-radio :value="1">开启</a-radio>
        <a-radio :value="0">关闭</a-radio>
      </a-radio-group>
    </a-form-item>
    <a-form-item label=" " :colon="false">
      <a-input v-decorator="['white_uids', decorator.white_uids]"
               :disabled="readonly"
               placeholder="白名单用户 ID"/>
      <div class="input-hint">请填入用户 ID, 逗号分隔。默认携带 token</div>
    </a-form-item>

    <a-form-item label="显示位置">
      <a-radio-group v-decorator="['is_type', decorator.is_type]"
                     name="jump_way"
                     :disabled="readonly"
                     placeholder="请选择显示位置">
        <a-radio value="index">App顶端</a-radio>
        <a-radio value="shop">积分商城</a-radio>
        <a-radio value="activity">固定入口</a-radio>
        <a-radio value="find">发现</a-radio>
      </a-radio-group>
    </a-form-item>

    <a-form-item label="banner 类型">
      <a-radio-group v-decorator="['jump_way', decorator.jump_way]"
                     name="jump_way"
                     :disabled="readonly">
        <!--<a-radio :value="0">不跳转</a-radio>-->
        <!--<a-radio :value="3">APP内跳转</a-radio>-->
        <a-radio :value="1">H5链接</a-radio>
        <a-radio :value="2">微信小程序</a-radio>
      </a-radio-group>
    </a-form-item>
    <a-form-item label="banner 链接">
      <a-input v-decorator="['url', decorator.url]"
               :disabled="readonly"
               placeholder="跳转链接"/>
    </a-form-item>

    <a-form-item label="推荐值">
      <a-input v-decorator="['is_num', decorator.is_num]"
               :disabled="readonly"
               placeholder="推荐值"/>
      <div>注：推荐值不能为空，必须为正整数，从小到大排列</div>
    </a-form-item>

    <a-form-item label=" " :colon="false">
      <a-button :disabled="readonly" type="primary" @click="onSave">
        保存
      </a-button>
      <a-button @click="onBack">取消</a-button>
    </a-form-item>
  </a-form>
</template>

<script>
import * as moment from 'moment';

import {
  uploadFileToPublic,
} from '@/api/Common';
import {
  getStickerDetail,
  saveSticker,
} from '../api/sticker';

const decorator = {
  pic: {
    valuePropName: 'fileList',
    getValueFromEvent: (e) => Array.isArray(e) ? e : (e && e.fileList),
    rules: [{ required: true, message: '请上传图片' }],
  },
  is_token: {
    rules: [{ required: true, message: '请选择是否需要 token' }],
  },
  shutup_time: {
    rules: [{ required: false, message: '请选择上架时间' }],
  },
  shutdown_time: {
    rules: [{ required: false, message: '请选择下架时间' }],
  },
  is_white: {
    rules: [{ required: true, message: '请选择是否需要开启白名单' }],
  },
  white_uids: {
    rules: [{ required: false, message: '请填入白名单用户 ID' }],
  },
  is_type: {
    rules: [{ required: true, message: '请选择 banner 显示位置' }],
  },
  jump_way: {
    rules: [{ required: true, message: '请选择 banner 类型' }],
  },
  url: {
    rules: [{ required: true, message: '请输入跳转链接' }],
  },
  is_num: {
    rules: [{ required: true, message: '请输入推荐值' }],
  },
};

export default {
  name: 'Detail',
  data() {
    return {
      loading: false,
      uploading: false, // 是否正在上传图片
      readonly: this.$route.path.indexOf('/detail') > -1,

      id: this.$route.query.id,

      decorator,

      pic: '',
      shutUpTime: undefined,
      shutDownTime: undefined,
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {});
  },
  created() {
    this.getData();
  },
  mounted() {
    if (!this.id) {
      this.setDecorator({
        is_token: 0,
        is_type: 'index',
        jump_way: 1,
        is_num: 1,
        is_white: 1,
      });
    }
  },
  methods: {
    onBack() {
      this.$router.back();
    },

    onSave() {
      this.form.validateFields((error, values) => {
        if (!error) {
          const params = {
            ...values,
            type: 2, // banner 固定字段
            pic: this.pic,
            shutup_time: values.shutup_time?.format('YYYY-MM-DD HH:mm:ss') || '',
            shutdown_time: values.shutdown_time?.format('YYYY-MM-DD HH:mm:ss') || '',
          };
          if (this.id) {
            params.id = this.id;
          }

          if (params.is_white === 0) {
            params.white_uids = '';
          }

          if (params.jump_way === 1 && !params.url.startsWith('https://')) {
            this.$message.warning('h5 链接必须以 https:// 开头');
            return;
          }

          this.save(params);
        }
      });
    },
    async save(params) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await saveSticker(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '保存失败');
        return;
      }
      this.$message.success(data.message || '保存成功');

      this.onBack();
    },

    async getData() {
      if (!this.id) {
        return;
      }
      this.loading = true;
      const data = await getStickerDetail({ id: this.id }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '获取数据失败');
        return;
      }
      this.pic = data?.data?.pic || '';
      this.setDecorator(data?.data || {});
    },

    setDecorator(data = {}) {
      this.whiteStatusChange({ target: { value: data.is_white || 0 } });

      const values = {};
      Object.keys(this.decorator).forEach((key) => {
        values[key] = data[key] ?? undefined;
      });

      values.pic = data.pic ? [{
        uid: data.pic,
        name: data.pic,
        status: 'done',
        url: data.pic,
      }] : undefined;
      values.shutup_time = data.shutup_time ? moment(data.shutup_time * 1000) : undefined;
      values.shutdown_time = data.shutdown_time ? moment(data.shutdown_time * 1000) : undefined;

      this.form.setFieldsValue(values);
    },

    whiteStatusChange({ target } = {}) {
      const white_uids = {
        rules: [{ required: false, message: '请填入白名单用户 ID' }],
      };
      if ([1].includes(target?.value)) {
        white_uids.rules[0].required = true;
      }
      this.decorator = {
        ...this.decorator,
        white_uids,
      };
    },

    shutUpTimeChange(date) {
      this.shutUpTime = date;
    },
    shutDownTimeChange(date) {
      this.shutDownTime = date;
    },

    disabledStartDate(date) {
      const now = new moment();
      if (!date) {
        return true;
      }
      if (this.shutDownTime) {
        return (
          date.isBefore(now, 'date') || date.isAfter(this.shutDownTime, 'date')
        );
      }
      return date.isBefore(now, 'date');
    },
    disabledStartTime(date) {
      if (!date) {
        return {
          disabledHours: () => this.range(0, 24),
          disabledMinutes: () => [0, 60],
          disabledSeconds: () => [0, 60],
        };
      }
      if (this.shutDownTime && date.isSame(this.shutDownTime, 'date')) {
        const endHour = this.shutDownTime.hour();
        const endMinute = date.isSame(this.shutDownTime, 'minute')
          ? this.shutDownTime.minute()
          : 0;
        if (date.isSame(this.shutDownTime, 'date')) {
          return {
            disabledHours: () => (endHour ? this.range(endHour, 24) : []),
            disabledMinutes: () => (endMinute ? this.range(endMinute, 60) : []),
            disabledSeconds: () => [],
          };
        }
      }

      const now = new moment();
      const hour = date.isSame(now, 'date') ? now.hour() : 0;
      const minute =
        date.isSame(now, 'date') && !date.isAfter(now, 'hour')
          ? now.minute()
          : 0;
      return {
        disabledHours: () => (hour ? this.range(0, hour) : []),
        disabledMinutes: () => (minute ? this.range(0, minute) : []),
        disabledSeconds: () => [],
      };
    },

    disabledEndDate(date) {
      const now = new moment();
      if (!date) {
        return true;
      }
      if (this.shutUpTime) {
        return (
          date.isBefore(now, 'date') || date.isBefore(this.shutUpTime, 'date')
        );
      }
      return date.isBefore(now, 'date');
    },
    disabledEndTime(date) {
      if (!date) {
        return {
          disabledHours: () => this.range(0, 24),
          disabledMinutes: () => [0, 60],
          disabledSeconds: () => [0, 60],
        };
      }
      const now = new moment();
      const beforeDate =
        this.shutUpTime && this.shutUpTime.isAfter(now) ? this.shutUpTime : now;

      const hour = date.isSame(beforeDate, 'date') ? beforeDate.hour() : 0;
      const minute =
        date.isSame(beforeDate, 'date') && !date.isAfter(beforeDate, 'hour')
          ? beforeDate.minute()
          : 0;
      return {
        disabledHours: () => (hour ? this.range(0, hour) : []),
        disabledMinutes: () => (minute ? this.range(0, minute) : []),
        disabledSeconds: () => [],
      };
    },

    range(start, end) {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    },

    beforeUpload(file) {
      const types = ['jpg', 'jpeg', 'png'];
      const type = file.type;
      const size = file.size;

      return new Promise((resolve, reject) => {
        if (types.every((t) => !type.includes(t))) {
          this.$message.info('图片仅支持 .jpg .jpeg .png 格式，请重新选择图片');
          reject('ImageTypeError');
        } else if (size > 2 * 1024 * 1024) {
          this.$message.info('图片大小不能超过 2M，请重新选择图片');
          reject('ImageSizeError');
        } else {
          resolve();
          // const imgUrl = URL.createObjectURL(file);
          // const imgEl = new Image();
          // imgEl.src = imgUrl;
          // imgEl.onload = () => {
          //   const rate = (480 / 270).toFixed(1);
          //   const imgRate = (imgEl.width / (imgEl.height || 1)).toFixed(1);
          //
          //   if (rate !== imgRate) {
          //     this.$message.info('图片支持尺寸为：480*270，请重新选择图片');
          //     reject('ImageRateError');
          //   } else {
          //     resolve();
          //   }
          // };
        }
      });
    },
    async customRequest({ file }) {
      if (this.uploading) {
        return;
      }
      this.uploading = true;

      const params = new FormData();
      params.append('file', file);
      params.append('source', 'common');

      const data = await uploadFileToPublic(params);
      if (!data || data.error_code) {
        this.$message.error(data?.message || '图片上传失败');
        return;
      }
      this.$message.success(data.message || '图片上传成功');

      this.uploading = false;
      this.pic = data.data.url;
    },
  },
};
</script>

<style scoped lang="scss">
.cover-img {
  max-width: 360px;
  height: 150px;
}

.input-hint {
  font-size: 12px;
  color: $light02;
}
</style>
