<template>
  <div>
    <div class="right-top-btn-group">
      <a-button class="right-top-btn" @click="create">添加 banner</a-button>
    </div>

    <div class="flex flex-start flex-wrap ph-20 mb-10">
      <div class="flex mr-30 mb-10">
        <span class="flex-none">推送岗位：</span>
        <app-select-job v-model="form.postIds"/>
      </div>
      <div class="flex mr-30 mb-10">
        <span class="flex-none">推送组织机构：</span>
        <app-select-org v-model="form.orgIds"/>
      </div>
      <div class="flex mr-30 mb-10">
        <span class="flex-none">状态：</span>
        <a-select v-model="form.status" style="width: 120px;">
          <a-select-option :value="0">全部</a-select-option>
          <a-select-option :value="1">上架</a-select-option>
          <a-select-option :value="2">下架</a-select-option>
        </a-select>
      </div>

      <div class="flex mr-30 mb-10">
        <span class="flex-none">定时上架时间：</span>
        <a-range-picker v-model="form.onTime" show-time/>
      </div>
      <div class="flex mr-30 mb-10">
        <span class="flex-none">定时下架时间：</span>
        <a-range-picker v-model="form.offTime" show-time/>
      </div>
    </div>

    <div class="flex flex-end ph-20 mb-10">
      <a-button type="primary" @click="onSearch()">搜索</a-button>
      <a-button @click="onReset(true)">重置</a-button>
    </div>

    <div class="ph-20 mb-10">
      <a-table :columns="columns"
               :data-source="data"
               :pagination="false"
               :loading="fetching || saving"
               bordered
               row-key="id"
               class="table small-cell-table">
        <template slot="pic" slot-scope="text">
          <app-image-viewer :url="text"/>
        </template>
        <template slot="post_arr" slot-scope="text">
          <span v-for="item of text" :key="item.id">{{ item.name }}</span>
        </template>
        <template slot="org_arr" slot-scope="text">
          <span v-for="item of text" :key="item.id">{{ item.name }}</span>
        </template>
        <template slot="shutup_time" slot-scope="text">
          {{ formatTime(text) }}
        </template>
        <template slot="shutdown_time" slot-scope="text">
          {{ formatTime(text) }}
        </template>
        <template slot="action" slot-scope="text, record">
          <div class="table-cell-wrapper">
            <a-button v-if="record.status === 1" type="link" size="small" @click="pullOff(record)">下架</a-button>
            <a-button v-else type="link" size="small" @click="pullOn(record)">上架</a-button>
            <a-button v-if="record.status === 1" type="link" size="small" @click="viewItem(record)">详情</a-button>
            <a-button v-else type="link" size="small" @click="editItem(record)">编辑</a-button>
            <a-button type="link" size="small" @click="deleteRecord(record)">删除</a-button>
          </div>
        </template>
        <template slot="footer">
          <Pagination v-if="pagination && pagination.total"
                      :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"/>
        </template>
      </a-table>
    </div>

    <FullContentModal ref="fullContentModalRef"/>
  </div>
</template>

<script>
import moment from 'moment';
import table from '@/mixins/table';

import {
  getStickerList,
  pullOn,
  pullOff,
  deleteSticker,
} from '../api/sticker';

import FullContentModal from '@/components/FullContentModal';

const defaultForm = {
  postIds: '',
  orgIds: '',
  status: 0,
  onTime: [undefined, undefined],
  offTime: [undefined, undefined],
};

const columns = [
  {
    width: '60px',
    align: 'center',
    dataIndex: 'id',
    key: 'id',
    title: 'ID',
  },
  {
    width: '160px',
    align: 'center',
    title: '图片',
    dataIndex: 'pic',
    key: 'pic',
    scopedSlots: { customRender: 'pic' },
  },
  {
    width: '100px',
    align: 'center',
    title: '跳转类型',
    dataIndex: 'jump_way',
    key: 'jump_way',
    customRender: (text) => ({
      0: '不跳转',
      1: 'H5',
      2: '小程序',
      3: 'App',
    }[text] || ''),
  },
  {
    width: '180px',
    align: 'center',
    title: '跳转链接',
    key: 'url',
    dataIndex: 'url',
  },
  {
    width: '80px',
    align: 'center',
    title: 'token',
    key: 'is_token',
    dataIndex: 'is_token',
    customRender: (text) => text ? '需要' : '不需要',
  },
  {
    width: '80px',
    align: 'center',
    title: '展示位置',
    key: 'is_type',
    dataIndex: 'is_type',
    customRender: (text) => ({ shop: '积分商城', index: 'app顶端', activity: '固定入口', find: '发现' }[text] || ''),
  },
  {
    width: '80px',
    align: 'center',
    title: '推荐值',
    key: 'is_num',
    dataIndex: 'is_num',
  },
  {
    width: '130px',
    align: 'center',
    title: '上架状态',
    key: 'status',
    dataIndex: 'status',
    customRender: (text) => ({ 1: '上架', 2: '下架' }[text] || ''),
    customCell: (record) => {
      return {
        style: {
          color: { 1: '#2ee92e', 2: '#ff3c3c' }[record.status] || '',
        },
      };
    },
  },
  {
    width: '130px',
    align: 'center',
    title: '上架时间',
    key: 'shutup_time',
    dataIndex: 'shutup_time',
    scopedSlots: { customRender: 'shutup_time' },
  },
  {
    width: '130px',
    align: 'center',
    title: '下架时间',
    key: 'shutdown_time',
    dataIndex: 'shutdown_time',
    scopedSlots: { customRender: 'shutdown_time' },
  },
  {
    width: '120px',
    align: 'center',
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];

export default {
  name: 'List',
  mixins: [table],
  components: {
    FullContentModal,
  },
  computed: {
    formatTime() {
      return (time) => time ? moment(time * 1000).format('YYYY-MM-DD HH:mm:ss') : '--';
    },
  },
  data() {
    return {
      defaultForm: { ...defaultForm },
      form: { ...defaultForm },

      originColumns: columns,
    };
  },
  created() {
    this.onSearch();
  },
  methods: {
    create() {
      this.$router.push('/index/advertising/banner/create');
    },

    getData(page, pageSize) {
      this.executeGetData(async () => {
        const format = 'YYYY-MM-DD HH:mm:ss';

        const params = {
          page,
          page_size: pageSize,
          type: 2, // 获取 banner 时，值固定为 2
          org_ids: this.form.orgIds,
          post_ids: this.form.postIds,
          status: this.form.status,
          shutup_start: this.form.onTime?.[0]?.format(format) || '',
          shutup_down: this.form.onTime?.[1]?.format(format) || '',
          shutdown_start: this.form.offTime?.[0]?.format(format) || '',
          shutdown_down: this.form.offTime?.[1]?.format(format) || '',
        };

        const data = await getStickerList(params);
        if (!data || data.error_code) {
          this.$message.error(data?.message || '获取数据失败');
          return Promise.reject(data);
        }
        this.data = (data.data || []).map((i, index) => ({
          ...i,
          rowIndex: (page - 1) * 10 + (index + 1),
        }));
        return Promise.resolve(data);
      });
    },

    editItem(item) {
      if (!item || !item.id) {
        return;
      }
      this.$router.push({
        path: '/index/advertising/banner/edit',
        query: {
          id: item.id,
        },
      });
    },
    viewItem(item) {
      if (!item || !item.id) {
        return;
      }
      this.$router.push({
        path: '/index/advertising/banner/detail',
        query: {
          id: item.id,
        },
      });
    },

    pullOn(item) {
      this.$confirm({
        content: () => <div>确认上架？</div>,
        onOk: () => this.confirmPullOn(item),
      });
    },
    async confirmPullOn(item) {
      if (this.saving) {
        return;
      }
      this.saving = true;

      const data = await pullOn({ id: item.id }).finally(() => {
        this.saving = false;
      });
      if (!data || data.error_code) {
        this.$message.error({ content: data?.message || '上架失败' });
        return;
      }
      this.$message.success({ content: data?.message || '上架成功' });
      this.onRefreshPage();
    },
    pullOff(item) {
      this.$confirm({
        content: () => <div>确认下架？</div>,
        onOk: () => this.confirmPullOff(item),
      });
    },
    async confirmPullOff(item) {
      if (this.saving) {
        return;
      }
      this.saving = true;

      const data = await pullOff({ id: item.id }).finally(() => {
        this.saving = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '下架失败');
        return;
      }
      this.$message.success(data?.message || '下架成功');
      this.onRefreshPage();
    },

    deleteRecord(record) {
      this.$confirm({
        content: () => <div>确认删除？</div>,
        onOk: () => this.confirmDeleteRecord(record),
      });
    },
    async confirmDeleteRecord(record) {
      if (this.saving) {
        return;
      }
      this.saving = true;

      const data = await deleteSticker({ id: record.id }).finally(() => {
        this.saving = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '删除失败');
        return;
      }
      this.$message.success(data?.message || '删除成功');
      this.onRefreshPage();
    },

    showFullContent(content, title) {
      if (!content) {
        return;
      }
      if (this.$refs.fullContentModalRef?.show) {
        this.$refs.fullContentModalRef.show({ content, title });
      }
    },

  },
};
</script>

<style scoped lang="scss">
.table-img {
  width: 180px;
}

.select {
  width: 160px;

  ::v-deep .ant-select-selection {
    width: 100%;
  }
}
</style>
