<template>
  <div>
    <div class="right-top-btn-group">
      <a-button class="right-top-btn" @click="add">添加贴片</a-button>
    </div>

    <div class="row row-left">
      <div class="col col-left">
        <span>推送岗位：</span>
        <app-select-job v-model="selectedJobIds"/>
      </div>

      <div class="col col-left">
        <span>推送组织机构：</span>
        <app-select-org v-model="selectedOrgIds"/>
      </div>

      <div class="col col-left">
        <span>状态：</span>
        <a-select v-model="selectedStatus" class="ss-select select">
          <a-select-option :value="0">全部</a-select-option>
          <a-select-option :value="1">上架</a-select-option>
          <a-select-option :value="2">下架</a-select-option>
        </a-select>
      </div>

    </div>

    <div class="row row-left">
      <div class="col col-left">
        <span>定时上架时间：</span>
        <a-range-picker v-model="shutUpTime" show-time></a-range-picker>
      </div>

      <div class="col col-left">
        <span>定时下架时间：</span>
        <a-range-picker v-model="shutDownTime" show-time></a-range-picker>
      </div>

    </div>

    <div class="flex flex-end ph-20 mb-10">
      <a-button type="primary" @click="search">搜索</a-button>
      <a-button @click="reset">重置</a-button>
    </div>

    <div class="row">
      <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading" bordered
               row-key="id" class="table small-cell-table">
        <template slot="pic" slot-scope="text">
          <img :src="text" alt="" class="table-img"/>
        </template>
        <template slot="jump_way" slot-scope="text">
          <span v-if="text === 0">不跳转</span>
          <span v-if="text === 1">H5</span>
          <span v-if="text === 2">小程序</span>
          <span v-if="text === 3">App</span>
        </template>
        <template slot="alert_type" slot-scope="text">
          <span v-if="text === 1">一直弹</span>
          <span v-if="text === 2">弹一次</span>
        </template>
        <template slot="post_arr" slot-scope="text">
          <span v-for="item of text" :key="item.id">{{ item.name }}</span>
        </template>
        <template slot="org_arr" slot-scope="text">
          <span v-for="item of text" :key="item.id">{{ item.name }}</span>
        </template>
        <template slot="status" slot-scope="text">
          <span v-if="text === 1">上架</span>
          <span v-if="text === 2">下架</span>
        </template>
        <template slot="shutup_time" slot-scope="text">
          {{ formatTime(text) }}
        </template>
        <template slot="shutdown_time" slot-scope="text">
          {{ formatTime(text) }}
        </template>
        <template slot="action" slot-scope="text, record">
          <div class="table-cell-wrapper">
            <a-button v-if="record.status === 1" type="link" size="small" @click="pullOff(record)">下架</a-button>
            <a-button v-else type="link" size="small" @click="pullOn(record)">上架</a-button>
            <a-button v-if="record.status === 1" type="link" size="small" @click="viewItem(record)">详情</a-button>
            <a-button v-else type="link" size="small" @click="editItem(record)">编辑</a-button>
            <a-button type="link" size="small" @click="deleteItem(record)">删除</a-button>
            <a-button type="link" size="small" @click="showRecord(record)">点击记录</a-button>
          </div>
        </template>
        <template slot="footer">
          <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
    </div>

    <RecordModal v-if="recordShown" :item="selectedItem" @close="closeRecordModal"/>
    <FullContentModal ref="fullContentModalRef"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import { getStickerList, pullOn, pullOff, deleteSticker } from '../api/sticker';
import { stickerDetail } from '@/utils/Navs/PublicityNavs';

import Pagination, { getPagination } from '@components/Pagination';
import Common from '@/utils/Common';
import RecordModal from './components/RecordModal';
import FullContentModal from '@/components/FullContentModal';

export default {
  name: 'List',
  components: { Pagination, RecordModal, FullContentModal },
  computed: {
    ...mapState({}),
  },
  data() {
    return {
      loading: false,
      columns: [
        {
          width: '40px',
          align: 'center',
          dataIndex: 'id',
          key: 'id',
          title: 'ID',
        },
        {
          width: '160px',
          align: 'center',
          title: '图片',
          dataIndex: 'pic',
          key: 'pic',
          scopedSlots: { customRender: 'pic' },
        },
        {
          width: '100px',
          align: 'center',
          title: '跳转类型',
          dataIndex: 'jump_way',
          key: 'jump_way',
          scopedSlots: { customRender: 'jump_way' },
        },
        {
          width: '180px',
          align: 'center',
          title: '跳转链接',
          key: 'url',
          dataIndex: 'url',
        },
        {
          width: '100px',
          align: 'center',
          title: '弹出次数',
          key: 'alert_type',
          dataIndex: 'alert_type',
          scopedSlots: { customRender: 'alert_type' },
        },
        {
          width: '130px',
          align: 'center',
          title: '推送岗位',
          key: 'post_arr',
          dataIndex: 'post_arr',
          scopedSlots: { customRender: 'post_arr' },
          ellipsis: true,
          customCell: (record) => {
            return {
              style: {
                width: '130px',
                cursor: 'pointer',
              },
              props: {},
              on: {
                click: () => {
                  const list = (record['post_arr'] || []).map((i) => i.name);
                  this.showFullContent(list, '推送岗位详情');
                },
              },
            };
          },
        },
        {
          width: '130px',
          align: 'center',
          title: '推送组织机构',
          key: 'org_arr',
          dataIndex: 'org_arr',
          scopedSlots: { customRender: 'org_arr' },
          ellipsis: true,
          customCell: (record) => {
            return {
              style: {
                width: '1030px',
                cursor: 'pointer',
              },
              props: {},
              on: {
                click: () => {
                  const list = (record['org_arr'] || []).map((i) => i.name);
                  this.showFullContent(list, '推送组织机构详情');
                },
              },
            };
          },
        },
        {
          width: '80px',
          align: 'center',
          title: '优先级',
          key: 'is_num',
          dataIndex: 'is_num',
        },
        {
          width: '130px',
          align: 'center',
          title: '上架状态',
          key: 'status',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
        },
        {
          width: '130px',
          align: 'center',
          title: '上架时间',
          key: 'shutup_time',
          dataIndex: 'shutup_time',
          scopedSlots: { customRender: 'shutup_time' },
        },
        {
          width: '130px',
          align: 'center',
          title: '下架时间',
          key: 'shutdown_time',
          dataIndex: 'shutdown_time',
          scopedSlots: { customRender: 'shutdown_time' },
        },
        {
          width: '150px',
          align: 'center',
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      data: [],
      titles: [{ title: '贴片配置' }, { title: '贴片列表' }],

      pagination: getPagination(),

      selectedItem: {},
      recordShown: false,

      selectedJobIds: '',
      selectedOrgIds: '',
      selectedStatus: 0,
      shutUpTime: [],
      shutDownTime: [],
    };
  },
  created() {
    this.getStickerList();
  },
  methods: {
    add() {
      const path = `${stickerDetail[0]?.path}`;
      this.$router.push(path);
    },

    formatTime(time) {
      return time ? Common.formatDateTime(time * 1000) : '--';
    },

    search() {
      this.getStickerList(1, this.pagination.pageSize);
    },
    reset() {
      this.selectedOrgIds = '';
      this.selectedJobIds = '';
      this.selectedStatus = 0;
      this.shutUpTime = [];
      this.shutDownTime = [];
    },

    showRecord(item) {
      this.selectedItem = item;
      this.recordShown = true;
    },
    closeRecordModal() {
      this.selectedItem = {};
      this.recordShown = false;
    },

    viewItem(item) {
      if (!item || !item.id) {
        return;
      }
      const path = `${stickerDetail[2]?.path}?id=${item.id}`;
      this.$router.push(path);
    },

    editItem(item) {
      if (!item || !item.id) {
        return;
      }
      const path = `${stickerDetail[1]?.path}?id=${item.id}`;
      this.$router.push(path);
    },

    pullOn(item) {
      this.$confirm({
        content: () => <div>确认上架？</div>,
        onOk: () => {
          this.confirmPullOn(item);
        },
      });
    },
    async confirmPullOn(item) {
      this.loading = true;
      const data = await pullOn({ id: item.id }).finally(() => this.loading = false);
      if (!data || data.error_code) {
        if (data?.error_code === 501) {
          // const basis = data?.data?.basis || [];
          // const required = data?.data?.required || [];
          // const question_num = data?.data?.question_num || [];
          this.$notice({
            title: data?.message || '上架失败',
          });
          // this.$notice({
          //   title: data?.message || '上架失败',
          //   content: h => h('div', {}, [
          //     h('p'),
          //     basis && basis.length ? h('div', {}, [
          //       h('h3', '基本信息'),
          //       ...basis.map(i => h('p', i || '')),
          //     ]) : null,
          //     required && required.length ? h('div', {}, [
          //       h('h3', '题目设置'),
          //       ...required.map(i => h('p', i || '')),
          //     ]) : null,
          //     question_num && question_num.length ? h('div', {}, [
          //       h('h3', '模式设置'),
          //       ...question_num.map(i => h('p', i || '')),
          //     ]) : null,
          //   ]),
          // });
          return;
        }
        return this.$message.error({ content: data?.message || '上架失败' });
      }
      this.$message.success({ content: data?.message || '上架成功' });
      this.getStickerList(this.pagination.current, this.pagination.pageSize);
    },
    pullOff(item) {
      this.$confirm({
        content: () => <div>确认下架？</div>,
        onOk: () => {
          this.confirmPullOff(item);
        },
      });
    },
    async confirmPullOff(item) {
      this.loading = true;
      const data = await pullOff({ id: item.id }).finally(() => this.loading = false);
      if (!data || data.error_code) {
        if (data?.error_code === 501) {
          // const basis = data?.data?.basis || [];
          // const required = data?.data?.required || [];
          // const question_num = data?.data?.question_num || [];
          // const list = [].concat(...basis, ...required, ...question_num);
          this.$notice({
            title: data?.message || '下架失败',
          });
          // this.$notice({
          //   title: data?.message || '下架失败',
          //   content: h => h('div', {}, [h('p'), ...list.map(i => h('p', i || ''))]),
          // });
          return;
        }
        return this.$message.error({ content: data?.message || '下架失败' });
      }
      this.$message.success({ content: data?.message || '下架成功' });
      this.getStickerList(this.pagination.current, this.pagination.pageSize);
    },

    deleteItem(item) {
      this.$confirm({
        content: () => <div>确认删除贴片？</div>,
        onOk: () => {
          this.confirmDeleteItem(item);
        },
      });
    },
    async confirmDeleteItem(item) {
      this.loading = true;
      const data = await deleteSticker({ id: item.id }).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return this.$message.error({ content: data?.message || '删除失败' });
      }
      this.$message.success({ content: data?.message || '删除成功' });
      this.data = this.data && this.data.length ? this.data.filter(i => i.id !== item.id) : [];

      const cur = this.pagination.current;
      const page = !this.data?.length ? (cur > 1 ? cur - 1 : 1) : cur;
      this.getStickerList(page, this.pagination.pageSize);
    },

    onPageChange(page, pageSize) {
      this.getStickerList(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getStickerList(1, size);
    },
    async getStickerList(page, pageSize) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;
      if (this.loading) {
        return;
      }
      this.loading = true;

      const params = {
        org_ids: this.selectedOrgIds,
        post_ids: this.selectedJobIds,
        page: page,
        page_size: pageSize,
        type: 1,
      };
      if (this.selectedStatus) {
        params.status = this.selectedStatus;
      }

      const format = 'YYYY-MM-DD HH:mm:ss';

      if (this.shutUpTime && this.shutUpTime.length >= 2) {
        params.shutup_start = this.shutUpTime[0].format(format);
        params.shutup_down = this.shutUpTime[1].format(format);
      }

      if (this.shutDownTime && this.shutDownTime.length >= 2) {
        params.shutdown_start = this.shutDownTime[0].format(format);
        params.shutdown_down = this.shutDownTime[1].format(format);
      }

      const data = await getStickerList(params).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      this.data = data?.data || [];

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },


    showFullContent(content, title) {
      if (!content) {
        return;
      }
      if (this.$refs.fullContentModalRef?.show) {
        this.$refs.fullContentModalRef.show({ content, title });
      }
    },

  },
};
</script>

<style scoped lang="scss">
.right-top-btn-group {
  position: relative;
  top: -40px;
  float: right;
  height: 0;
}

.table-img {
  width: 180px;
}

.select {
  width: 160px;

  ::v-deep .ant-select-selection {
    width: 100%;
  }
}
</style>
